import {baseUrl, getImage} from '@/constants';
import {Head, usePage} from '@inertiajs/react';
import {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

export default function ({title}: {title?: string}): ReactNode {
    const {
        props: {setting, currentLang},
    }: any = usePage();
    const {t} = useTranslation();

    return (
        <Head>
            <title>{title ? t(title) : setting.name}</title>
            <meta name="description" content={setting.description} />
            <meta property="og:type" content={setting.name} />
            <meta property="description" content={setting.name} />
            <meta property="og:locale" content={currentLang} />
            <meta property="og:site_name" content={setting.name} />
            <meta property="og:url" content={baseUrl} />
            <meta property="og:title" content={setting.name} />
            <meta property="og:description" content={setting.description} />
            <meta property="og:image" content={setting.thumb} />
            <link
                rel="apple-touch-icon"
                sizes="57x57"
                href="/images/icons/fav/apple-icon-57x57.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="60x60"
                href="/images/icons/fav/apple-icon-60x60.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="72x72"
                href="/images/icons/fav/apple-icon-72x72.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="76x76"
                href="/images/icons/fav/apple-icon-76x76.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="114x114"
                href="/images/icons/fav/apple-icon-114x114.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="120x120"
                href="/images/icons/fav/apple-icon-120x120.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="144x144"
                href="/images/icons/fav/apple-icon-144x144.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="152x152"
                href="/images/icons/fav/apple-icon-152x152.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/images/icons/fav/apple-icon-180x180.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="192x192"
                href="/images/icons/fav/android-icon-192x192.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/images/icons/fav/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="/images/icons/fav/favicon-96x96.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/images/icons/fav/favicon-16x16.png"
            />
            <link rel="manifest" href="/manifest.json" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
                name="msapplication-TileImage"
                content="/ms-icon-144x144.png"
            />
            <meta name="theme-color" content="#ffffff"></meta>
            <link
                rel="icon"
                href={setting.thumb}
                type="image/png"
                sizes="any"
            />
            <link
                rel="apple-touch-icon"
                href={setting.thumb}
                type="image/png"
                sizes="any"
            />
            <meta property="og:image:alt" content={setting.name} />
            <meta property="og:mobile" content={setting.mobile} />
            <meta property="og:whatsapp" content={setting.whatsapp} />
            <meta property="instagram:url" content={baseUrl} />
            <meta property="instagram:title" content={setting.name} />
            <meta property="instagram:description" content={setting.name} />
            <meta property="instagram:image" content={setting.thumb} />
            <meta property="twitter:url" content={baseUrl} />
            <meta property="twitter:title" content={setting.name} />
            <meta property="twitter:description" content={setting.name} />
            <meta property="twitter:image" content={setting.thumb} />
            <meta property="facebook:url" content={baseUrl} />
            <meta property="facebook:title" content={setting.name} />
            <meta property="facebook:description" content={setting.name} />
            <meta property="facebook:image" content={setting.thumb} />
            <meta property="og:type" content={setting.name} />
            <meta property="description" content={setting.description} />
            <meta property="og:locale" content={currentLang} />
            <meta property="og:site_name" content={setting.name} />
            <meta property="og:url" content={baseUrl} />
            <meta property="og:title" content={setting.name} />
            <meta property="og:description" content={setting.name} />
            <meta property="og:image" content={setting.thumb} />
            <meta property="og:image:alt" content={setting.name} />
            <meta property="og:mobile" content={setting.mobile} />
            <meta property="og:whatsapp" content={setting.whatapp} />
            <meta property="instagram:url" content={setting.name} />
            <meta property="instagram:title" content={setting.name} />
            <meta property="instagram:description" content={setting.name} />
            <meta property="instagram:image" content={setting.thumb} />
            <meta property="twitter:url" content={baseUrl} />
            <meta property="twitter:title" content={setting.name} />
            <meta property="twitter:description" content={setting.name} />
            <meta property="twitter:image" content={setting.thumb} />
            <meta property="facebook:url" content={baseUrl} />
            <meta property="facebook:title" content={setting.name} />
            <meta property="facebook:description" content={setting.name} />
        </Head>
    );
}
